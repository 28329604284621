import "./App.css";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

export const Loadable = (Components) => (props) => {
  return (
    <Suspense>
      <Components {...props} />
    </Suspense>
  );
};

const Login = Loadable(lazy(() => import("./components/auth/login")));
const ForgetPassword = Loadable(
  lazy(() => import("./components/auth/ForgetPassword"))
);
const AddLead = Loadable(lazy(() => import("./pages/AddLead")));
const Qualifying = Loadable(lazy(() => import("./pages/Qualifying")));
const SubAdmin = Loadable(lazy(() => import("./pages/users/SubAdmin")));
const SubAdminID = Loadable(lazy(() => import("./pages/users/SubAdminID")));

const MedicalFacility = Loadable(
  lazy(() => import("./pages/Medicle_facility/MedicalFacility"))
);
const Manageattorney = Loadable(
  lazy(() => import("./pages/Attorney/Manageattorney"))
);
const ManageattorneyID = Loadable(
  lazy(() => import("./pages/Attorney/ManageattorneyID"))
);
const Attorneydetails = Loadable(
  lazy(() => import("./pages/Attorney/Attorneydetails"))
);
const Uberrequest = Loadable(lazy(() => import("./pages/Uber/Uberrequest")));
const Lyftrequest = Loadable(lazy(() => import("./pages/Uber/Lyftrequest")));
const Profile = Loadable(lazy(() => import("./pages/users/Profile")));
const ManageMedicalFacility = Loadable(
  lazy(() => import("./pages/Medicle_facility/ManageMedicalFacility"))
);
const Medicalclientlist = Loadable(
  lazy(() => import("./pages/Medicle_facility/MedicalClientList"))
);
const MedicalClientManage = Loadable(
  lazy(() => import("./pages/Medicle_facility/MedicalClientManages"))
);
const ManageMedicalFacilityID = Loadable(
  lazy(() => import("./pages/Medicle_facility/ManageMedicalFacilityID"))
);
const AttorneyClientList = Loadable(
  lazy(() => import("./pages/Attorney/AttorneyClientList"))
);
const AttorneyClientManage = Loadable(
  lazy(() => import("./pages/Attorney/AttorneyClientManage"))
);
const Billing = Loadable(lazy(() => import("./pages/Billing")));
const BillingID = Loadable(lazy(() => import("./pages/BillingID")));

const Calender = Loadable(lazy(() => import("./pages/Calender")));
const Privatecomponent = Loadable(
  lazy(() => import("./components/auth/Privatecomponent"))
);
const Attendance = Loadable(lazy(() => import("./pages/Attendance")));
const ChangePassword = Loadable(
  lazy(() => import("./components/auth/ChangePassword"))
);
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard")));
const Userlist = Loadable(lazy(() => import("./pages/users/Userlist")));
const Voucher = Loadable(lazy(() => import("./pages/Voucher")));
const Table = Loadable(lazy(() => import("./components/table/BasicTable")));
const Qa = Loadable(lazy(() => import("./pages/Qa")));
const Logs = Loadable(lazy(() => import("./pages/Logs")));

const Userdetails = Loadable(lazy(() => import("./pages/users/UserDetails")));

function App() {
  return (
    <Routes>
      <Route element={<Privatecomponent />}>
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/sub-admin" element={<SubAdmin />} />
        <Route path="/sub-admin/:id" element={<SubAdminID />} />
        <Route path="/user-list" element={<Userlist />} />
        <Route path="/add-lead" element={<AddLead />} />
        <Route path="/qualifying" element={<Qualifying />} />
        <Route path="/medical-facility" element={<MedicalFacility />} />
        <Route path="/medical-client-list" element={<Medicalclientlist />} />
        <Route path="/user-details" element={<Userdetails />} />
        <Route
          path="/medical-client-manage"
          element={<MedicalClientManage />}
        />

        <Route
          path="/manage-medical-facility"
          element={<ManageMedicalFacility />}
        />
        <Route
          path="/manage-medical-facility/:id"
          element={<ManageMedicalFacilityID />}
        />
        <Route path="/manage-attorney" element={<Manageattorney />} />
        <Route path="/manage-attorney/:id" element={<ManageattorneyID />} />

        <Route path="/voucher" element={<Voucher />} />
        <Route path="/attorney-details" element={<Attorneydetails />} />
        <Route path="/attorney-client-list" element={<AttorneyClientList />} />
        <Route
          path="/attorney-client-manage"
          element={<AttorneyClientManage />}
        />
        <Route path="/uber-request" element={<Uberrequest />} />
        <Route path="/lyft-request" element={<Lyftrequest />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/billing/:id" element={<BillingID />} />
        <Route path="/calender" element={<Calender />} />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="*" element={<Dashboard />}></Route>
        <Route path="table" element={<Table />}></Route>
        <Route path="/qa" element={<Qa />}></Route>
        <Route path="/logs" element={<Logs />}></Route>


        <Route path="/change_password" element={<ChangePassword />}></Route>
      </Route>
      <Route path="/" element={<Login />} />
      <Route path="/forgetpassword" element={<ForgetPassword />} />
    </Routes>
  );
}

export default App;
